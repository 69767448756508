<template>
  <land-section
    id="my_album_header"
    space="30"
  >
    <v-container
      style="max-width: 1800px;"
    >
      <v-row
        dense
      >
        <template
          v-if="items.length"
        >
          <v-col
            v-for="(item, i) in items"
            :key="`img_${i}`"
            cols="12"
            lg="3"
            md="4"
            sm="6"
          >
            <v-hover
              v-slot="{ hover }"
              close-delay="100"
            >
              <v-card
                :elevation="hover ? hoverElevation : normalElevation"
                class="rounded transition-swing"
                color="white"
                flat
                tile
              >
                <v-img
                  :aspect-ratio="ratio"
                  :src="item.imageUrl"
                  height="inherit"
                >
                  <div
                    class="co-flex-col co-justify-between co-h-full"
                  >
                    <div
                      class="co-flex-col co-justify-end co-items-end co-w-full pa-2"
                    >
                      <pop-menu
                        :iconed="btnMore.iconed"
                        :texted="btnMore.texted"
                        :text="btnMore.text"
                        :icon="btnMore.icon"
                        :color="btnMore.color"
                        :actions="btnMore.items"
                        @action="onAction($event, i, item)"
                      />
                    </div>
                    <div
                      class="co-flex-col co-w-full co-flex-grow"
                      @click="onPreview(i, item)"
                    />
                    <div
                      v-if="hover"
                      class="co-flex-row co-justify-between co-items-center grey darken-4 co-h40 px-4"
                      style="opacity: 0.70;"
                    >
                      <span
                        class="text-body-1 white--text"
                      >
                        {{ item.title }}
                      </span>
                    </div>
                  </div>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </template>
        <template v-else>
          <v-col
            cols="12"
          >
            <div class="co-flex-col co-justify-center co-items-center pa-6">
              <span
                class="co-text-md co-text-gray-500"
              >
                本影集没有作品
                <span
                  class="co-text-md primary--text ml-3"
                  style="cursor: pointer;"
                >
                  从作品中添加
                </span>
              </span>
            </div>
          </v-col>
        </template>
      </v-row>

      <div
        v-if="pagination.length"
        class="d-flex flex-row mt-6"
      >
        <v-pagination
          v-model="pagination.current"
          :length="pagination.length"
          :total-visible="pagination.totalVisible"
          @input="changePagination($event)"
        />
      </div>
    </v-container>
    <dialog-image-preview ref="dlgImgPreview" />
  </land-section>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import lib from '@/api/co.lib'
  import web from '@/api/web/co.web'

  export default {
    components: {
      PopMenu: () => import('@/pages/sections/comm/PopMenu.vue'),
      DialogImagePreview: () => import('@/pages/sections/comm/DialogImagePreview.vue'),
    },
    data () {
      return {
        albumId: '',
        ratio: 1.45,
        hovered: true,
        normalElevation: 0,
        hoverElevation: 12,
        params: null,
        items: [],
        pagination: {
          current: 1,
          length: 0,
          totalVisible: 7
        },
        btnMore: {
          iconed: true,
          texted: false,
          text: '操作',
          icon: 'mdi-dots-horizontal',
          color: 'white',
          items: [{
            icon: 'mdi-trash-can-outline',
            text: '从影集中删除',
            value: 'remove'
          }, {
            icon: 'mdi-panorama-variant-outline',
            text: '设为影集封面',
            value: 'setcover'
          }]
        },
        handlers: {
          remove: this.toRemove,
          setcover: this.toSetCover
        }
      }
    },
    created () {
      const params = JSON.parse(lib.cookie.get('Album'))
      this.albumId = params.albumId || ''
      this.configParams(this.albumId)
      this.loadContents()
    },
    methods: {
      getImgs () {
        const imgs = []
        for (const i in this.items) {
          const item = this.items[i]
          imgs.push(item.imageUrl)
        }
        return imgs
      },
      onPreview (index, item) {
        const imgs = this.getImgs()
        const dlg = this.$refs.dlgImgPreview
        if (dlg) {
          dlg.show(imgs, index)
        }
      },
      addToAlbum (img) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            const dst = Object.assign({}, res.data)
            me.items.unshift(dst)
            me.$notify({
              title: '成功提示',
              message: '图片加入影集成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '加入影集失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }

        web.albumImg.create({
          data: {
            albumId: this.albumId,
            title: img.title,
            enabled: web.comm.Enables.ENABLED,
            accessScope: web.comm.Scopes.PUBLIC,
            imageUrl: img.imageUrl
          },
          executed
        })
      },
      add (imgList) {
        const list = imgList || []
        if (!this.albumId) {
          return
        }

        for (const i in list) {
          const src = list[i]
          this.addToAlbum(src)
        }
      },
      toRemove (index, item) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.items.splice(index, 1)
            me.$notify({
              title: '成功提示',
              message: '从影集中删除作品成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '从影集中删除作品失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }

        web.albumImg.delete({
          id: item.albumImageId,
          executed
        })
      },
      toSetCover (index, item) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            app.emit(app.event.ALBUM_COVER_CHANGE, {
              cover: item.imageUrl
            })
            me.$notify({
              title: '成功提示',
              message: '影集封面设置成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '影集封面设置失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }

        web.album.update({
          id: item.albumId,
          data: {
            cover: item.imageUrl
          },
          executed
        })
      },
      onAction (ev, index, item) {
        const rapid = ev.item
        const handler = this.handlers[rapid.value]
        if (typeof handler === 'function') {
          handler(index, item)
        }
      },
      changePagination (number) {
        const params = this.params
        const data = this.params.data
        const index = parseInt(number, 10) || 1

        this.pagination.current = index
        data.currentPage = index
        params.reload = true
        api.httpx.getItems(params)
      },
      configParams (albumId) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            const data = me.params.data
            const pagination = me.pagination
            const pageSize = parseInt(data.pageSize) || 12
            pagination.length = Math.ceil(res.total / pageSize)
          }
        }

        me.params = web.albumImg.getParams({
          albumId,
          pageSize: '12',
          caches: this.items,
          executed
        })
      },
      loadContents (reload = true) {
        this.params.reload = reload
        api.httpx.getItems(this.params)
      },
    }
  }
</script>
